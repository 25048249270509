import Vue from 'vue';
import TYPES from '@/types';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import { Values } from '@/modules/shared/domain/i18n/types';
import Translator from '@/modules/shared/domain/i18n/translator';

export default class DeleteAccountConfirmationViewModel {
  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  readonly view: Vue;

  readonly i18n_namespace = 'components.user-profile.user-profile-tabs.delete-account.delete-account-confirmation';

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  constructor(view: Vue) {
    this.view = view;
  }

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  nextStep = () => {
    this.view.$emit('nextStep');
  }
}
