













































import { Component, Vue } from 'vue-property-decorator';
import DeleteAccountConfirmationViewModel
  from '@/vue-app/view-models/components/user-profile/user-profile-tabs/user-profile-delete-account/delete-account-confirmation-view-model';

@Component({ name: 'DeleteAccountConfirmation' })
export default class DeleteAccountConfirmation extends Vue {
  delete_account_confirmation_view_model = Vue.observable(
    new DeleteAccountConfirmationViewModel(this),
  );
}
